import React, {Component} from 'react';
import Banner from './components/sections/Banner.js';
import Blurb from './components/sections/Blurb.js';
// import Listen from './components/sections/Listen.js';
import Subscribe from './components/sections/Subscribe.js';
import Contact from './components/sections/Contact.js';
import Socials from './components/sections/Socials.js';

class MainSections extends Component {
    render(){
        return (
            <div>
                <Banner />
                <Blurb />
                <Subscribe />
                {/* <Listen /> */}
                <Contact /> 
                <Socials />
            </div>
        );
    }
}

export default MainSections;
