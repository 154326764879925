import React, {Component} from 'react';

class Socials extends Component {
    render(){
        return (
        <div className="socials">
            <a href="https://twitter.com/AdventureTeamPr">
                <img src="/img/social-icons/twitter.png" alt="Twitter" />
            </a>
            <a href="https://www.facebook.com/adventureteampro/">
                <img src="/img/social-icons/facebook.png" alt="Facebook" />
            </a>
            <a href="http://adventureteampro.libsyn.com/">
                <img src="/img/social-icons/link.png" alt="Feed" />
            </a>
            <a href="https://www.instagram.com/adventureteampro/">
                <img src="/img/social-icons/instagram.png" alt="Instagram" />
            </a>
            <a href="https://www.youtube.com/channel/UCompIRBW2UNWHIw_0Wa-dIA">
                <img src="/img/social-icons/youtube.png" alt="Youtube" />
            </a>
        </div>
        );
    }
}

export default Socials;
