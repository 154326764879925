import React, {Component} from 'react';

class Banner extends Component {

    constructor(){
        super();
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e){
        e.preventDefault();
        document.getElementsByClassName('blurb')[0].scrollIntoView({
            behavior: "smooth"
        });
    }

    render(){
        return (
            <div className="banner">
                <div id="carrot" onClick={this.handleClick}>
                    <img src="/img/carrot.png" id="carrotImg" alt="carrot" /> 
                </div>
            </div>
        );
    }
}

export default Banner;
