import React, {Component} from 'react';

class Contact extends Component {
    render(){
        return (
        <div className="contact">
            <p>
                Adventure Team Pro is hosted by Jamie Williamson, Ben Frost, and Matt Trobbiani. 
                <br />
                Feel free to email us, at adventureteampro at gmail dot com.  
            </p>
        </div>
        );
    }
}

export default Contact;
