import React, { Component } from 'react';

class Header extends Component{
    render(){
        return (
            <header></header>
        );
    }
}

export default Header; 
