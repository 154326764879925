import React, { Component } from 'react';
import Header from './Header.js';
import MainSections from './MainSections.js';
import Footer from './Header.js';

class App extends Component {
    render(){
        return (
            <div>
                <Header />
                <MainSections />
                <Footer />
            </div>
        );
    }
}

export default App;
