import React, { Component } from 'react';

class Subscribe extends Component{
    render(){
        return (
            <div className="subscribe">
                <h1>Subscribe!</h1>
                <a target="blank" href="https://podcasts.apple.com/au/podcast/adventure-team-pro-danger-specialists/id1470574816">Apple Podcasts</a><br />
                <a target="blank" href="https://overcast.fm/itunes1470574816">Overcast</a><br />
                <a target="blank" href="https://www.stitcher.com/podcast/adventure-team-pro-danger-specialists">Stitcher</a><br />
                <a target="blank" href="https://open.spotify.com/show/4Ngkm5DkZEJGBwNa9vLDKM">Spotify</a><br />
                <a target="blank" href="http://adventureteampro.libsyn.com/">Online Feed</a><br />
                <a target="blank" href="http://adventureteampro.libsyn.com/rss">RSS Feed</a><br />
                <a target="blank" href="https://tunein.com/podcasts/Comedy-Podcasts/Adventure-Team-Pro-Danger-Specialists-p1231412/">TuneIn</a><br />
            </div>
        );
    }
}

export default Subscribe;
