import React, {Component} from 'react';

class Blurb extends Component {
    render(){
        return (
        <div className="blurb">
            <p>
                Strap on your spacesuit! Fight a tiger! Die a lot! 
                Our Danger Specialists are here and the time for adventuring is now! 
                Adventure Team Pro is a comedy show and podcast where our adventurers take on entirely 
                new adventure campaigns each episode. Can they prevail? Will they escape certain death 
                with a newly acquired signed spice girls poster? Tune in and find out! 
            </p>
        </div>
        );
    }
}

export default Blurb;
